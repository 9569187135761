'use client';

import React from 'react';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/navigation';
import {
    FreeMode,
    Mousewheel,
    Navigation,
    Pagination,
    Scrollbar,
} from 'swiper/modules';

import ProductSlide from './product-slide';

import './product-slide.css';

export function findObjectsWithSameShadeData(objects: any, shadeValue: any) {
    if (!shadeValue) {
        return objects;
    }
    return objects?.filter((obj: any) => {
        return obj?.variantAttributes?.some(
            (attr: any) =>
                attr.attribute === 'shade' && attr.value === shadeValue
        );
    });
}

export const filterUniqueShades = (data: any) => {
    const uniqueShades: { [key: string]: boolean } = {};

    return data?.filter((item: any) => {
        if (!uniqueShades[item?.value]) {
            uniqueShades[item.value] = true;
            return true;
        }
        return false;
    });
};

interface Slide {
    id: number;
    sku: string | any;
    path: string;
    width: number;
    height: number;
    brand?: any;
    type?: string;
    productName: string | any;
    price: string | any;
    cutPrice: string | any;
    Sizes?: string | any;
    Shade?: string | any;
    variantIds?: any;
    productId?: string;
    productVariantId?: string;
    variantShades?: any;
    variantSize?: any;
    wishlistSlide?: boolean;
    isOutOfStock: boolean;
}

interface CommonSliderProps {
    initialSlidesPerView?: number;
    breakpoints?: { [key: number]: { slidesPerView: number } };
    productSlide?: Slide[];
    bestSelling?: any;
    onclick?: any;
}

//const availableSizes = ['1 size', '2 size', '3 size', '4 size', '5 size'];
// const availableColors = ["#F59B9B", "#DFDEDC", "#E78779", "#E56D8D", "#E39999", "#D9D1C0", "#FBADA2", "#F6AABE"];

const CommonSlider: React.FC<CommonSliderProps> = ({
    initialSlidesPerView,
    breakpoints,
    productSlide=[],
    bestSelling = [],
}) => {
    const router = useRouter();
    // details page navigation
    const handleGetProductDetails = (item: any) => {
        const hasShade =
            item?.variantShades &&
            item?.variantShades?.find(
                (v: any) => v?.attribute === 'shade' && v._id === item.variantId
            );

        var url = '';
        var masterSku = item?.sku || item?.masterSku;
        if (item?.type === 'simple' || item.productType === 'simple') {
            url = `product-details?masterSku=${masterSku}`;
        } else {
            if (hasShade) {
                url = `product-details?masterSku=${masterSku}&variant=${hasShade?._id}&shade=${hasShade?.value}`;
            } else {
                url = `product-details?masterSku=${masterSku}&variant=${item?.variantId}`;
            }
        }

        if (isMobile) {
            router.push(url);
        } else {
            openInNewTab(url);
        }
    };

    const openInNewTab = (url: any) => {
        const newTab = window.open(url, '_blank');
        window.focus();
    };

    return (
        <>
            <div className=" rounder-md ">
                <Swiper
                    spaceBetween={!isMobile ? 25 : 5}
                    navigation={!isMobile}
                    rewind={true}
                    // pagination={{ clickable: true }}
                    freeMode={true}
                    modules={!isMobile ? [Navigation] : [FreeMode]}
                    breakpoints={breakpoints}
                    className="mySwiper2 "
                    slidesPerView={initialSlidesPerView}
                    initialSlide={0}
                >
                    {productSlide?.map((slide: any) => (
                        <SwiperSlide
                            className="rounded-lg @sm:pb-10 @md:pb-10 px-[0%] bg-transparent"
                            key={slide?.id}
                        >
                            <ProductSlide
                                SelectedColor=""
                                ColorName=""
                                id={slide?.id}
                                wishlistSlide={slide?.wishlistSlide}
                                BrandName={slide?.brand}
                                isOutOfStock={slide?.isOutOfStock}
                                cutPrice={slide?.mrp?.toFixed(2)}
                                Price={slide?.discountedPrice?.toFixed(2)}
                                productName={slide?.productName}
                                productId={slide?.productId}
                                productVariantId={slide?.productVariantId}
                                imagePath={slide?.path}
                                width={100}
                                height={100}
                                altText={`image`}
                                // className="w-full  rounded-xl h-[53vh] @2xl:h-[60vh] @xl:h-[58vh] @md:h-[37vh] @sm:h-[56vh]"
                                className="w-full rounded-lg h-full bg-white "
                                Sizes={slide?.Sizes}
                                Shade={slide?.Shade}
                                availableColors={filterUniqueShades(
                                    slide?.variantShades
                                )}
                                availableSizes={slide?.variantSize}
                                type={slide?.type}
                                variantId={slide?.variantIds}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleGetProductDetails(slide);
                                }}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/* {productSlide?.map((slide: any) => (
                <>
                    <ProductSlide
                        SelectedColor=""
                        ColorName=""
                        id={slide?.id}
                        wishlistSlide={slide?.wishlistSlide}
                        BrandName={slide?.brand}
                        isOutOfStock={slide?.isOutOfStock}
                        cutPrice={slide?.mrp?.toFixed(2)}
                        Price={slide?.discountedPrice?.toFixed(2)}
                        productName={slide?.productName}
                        productId={slide?.productId}
                        productVariantId={slide?.productVariantId}
                        imagePath={slide?.path}
                        width={100}
                        height={100}
                        altText={`image`}
                        // className="w-full  rounded-xl h-[53vh] @2xl:h-[60vh] @xl:h-[58vh] @md:h-[37vh] @sm:h-[56vh]"
                        className="w-full  rounded-lg h-full bg-white "
                        Sizes={slide?.Sizes}
                        Shade={slide?.Shade}
                        availableColors={filterUniqueShades(
                            slide?.variantShades
                        )}
                        availableSizes={slide?.variantSize}
                        type={slide?.type}
                        variantId={slide?.variantIds}
                        onClick={() => {
                            handleGetProductDetails(slide);
                        }}
                    />
                </>
            ))} */}
        </>
    );
};

export default CommonSlider;
