'use client';
import React, { useState } from 'react';

import FavoriteSharpIcon from '@mui/icons-material/FavoriteSharp';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';

interface HeartToggle {
    toggleHeartIcon?: boolean;
}

const HeartIcon: React.FC<HeartToggle> = ({ toggleHeartIcon }) => {
    const [clickedWishlist, setClickedWishlist] = useState(false);
    const token = getCookie('accessToken');
    const router = useRouter();

    const handleIconClick = () => {
        if (!token) {
            router.push('/login');
        } else {
            setClickedWishlist(!toggleHeartIcon);
        }
    };

    const iconComponent = toggleHeartIcon ? (
        <FavoriteSharpIcon
            className="@sm:text-18 text-18"
            style={{ color: '#d32f2f' }}
        />
    ) : (
        <FavoriteBorderOutlinedIcon
            className="@sm:text-18 text-18"
            style={{ color: '#3B3B3B33' }}
        />
    );

    return (
        <div
            className="cursor-pointer rounded-full p-1 bg-white"
            onClick={handleIconClick}
        >
            {iconComponent}
        </div>
    );
};

export default HeartIcon;
