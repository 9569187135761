// ProductSlide.tsx
'use client';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem } from '@/store/slices/actions/cart-actions';
import HeartIcon from '../heart-icon';
import {
    CreateWistlistData,
    RemoveWistlistData,
    getWistlistData,
} from '@/store/slices/actions/wishlist-action';
import { AppDispatch } from '@/store/store';
import { findObjectsWithSameShadeData } from './slider';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';

interface ProductSlideProps {
    imagePath: string;
    id?: string;
    width: number;
    height: number;
    altText: string;
    className?: string;
    BrandName: any;
    productName: string;
    Price: string;
    cutPrice: string;
    Sizes?: string;
    Shade?: any;
    sku?: string;
    availableColors: any;
    availableSizes?: any;
    SelectedColor?: string;
    ColorName: string;
    onClick?: any;
    type?: string;
    productId?: string;
    variantId?: any;
    wishlistSlide?: boolean;
    productVariantId?: any;
    isOutOfStock: boolean;
}

const ProductSlide: React.FC<ProductSlideProps> = ({
    imagePath,
    id,
    width,
    height,
    altText,
    className,
    BrandName,
    productName,
    Price,
    cutPrice,
    Sizes,
    availableColors,
    Shade,
    availableSizes,
    onClick,
    productId,
    type,
    variantId,
    productVariantId,
    wishlistSlide,
    sku,
    isOutOfStock,
}) => {
    const [showShadeOptions, setShowShadeOptions] = useState(false);
    const [showSizeOptions, setShowSizeOptions] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const router = useRouter();

    const [selectedColor, setSelectedColor] = useState<any>({
        color: availableColors?.length > 0 ? availableColors[0]?.hexCode : null,
        colorName:
            availableColors?.length > 0 ? availableColors[0]?.value : null,
    });

    const [selectedSizes, setSelectedSizes] = useState<any>({
        size: availableSizes?.length > 0 ? availableSizes[0]?.attribute : null,
        sizeValue: availableSizes?.length > 0 ? availableSizes[0]?.value : null,
    });

    const [toggleHeartIcon, setToggleHeartIcon] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const wishListData = useSelector(
        (state: any) => state.wishlist_store.wishListData
    );
    const [selectedVariant, setSelectedVariant] = useState<any>(null);

    useEffect(() => {
        if (wishListData?.length > 0) {
            if (type === 'variable') {
                setToggleHeartIcon(
                    wishListData?.some(
                        (item: any) =>
                            item.productVariantId === productVariantId
                    )
                );
            } else {
                setToggleHeartIcon(
                    wishListData?.some(
                        (item: any) => item.productId === productId
                    )
                );
            }
        }
    }, [productId, wishListData, productVariantId]);

    useEffect(() => {
        if (availableColors?.length > 0 && availableSizes?.length > 0) {
            setSelectedColor({
                color: availableColors[0]?.hexCode,
                colorName: availableColors[0]?.value,
                productId: availableColors[0]?.productId,
                productVariantId: availableColors[0]?._id,
            });
            setSelectedSizes({
                size: availableSizes[0]?.attribute,
                sizeValue: availableSizes[0]?.value,
                productId: availableSizes[0]?.productId,
                productVariantId: availableSizes[0]?._id,
            });
        } else if (availableColors?.length > 0) {
            setSelectedColor({
                color: availableColors[0]?.hexCode,
                colorName: availableColors[0]?.value,
                productId: availableColors[0]?.productId,
                productVariantId: availableColors[0]?._id,
            });
            // Clear selectedSize
            setSelectedSizes(null);
        } else if (availableSizes?.length > 0) {
            setSelectedSizes({
                size: availableSizes[0]?.attribute,
                sizeValue: availableSizes[0]?.value,
                productId: availableSizes[0]?.productId,
                productVariantId: availableSizes[0]?._id,
            });
            setSelectedColor(null);
        }
    }, [availableColors, availableSizes]);

    const handleColorClick = (color: string, variantIds: string) => {
        const selectedColorObject = availableColors?.find(
            (c: any) => c?.hexCode === color
        );
        setSelectedColor({
            color: color,
            colorName: selectedColorObject ? selectedColorObject?.value : null,
            productId: selectedColorObject?.productId,
            productVariantId: selectedColorObject?._id,
        });
        const variants = variantId?.map((item: any, index: any) => {
            const foundVariant = item?.productInventory?.find(
                (variant: any) => variant.productVariantId === variantIds
            );
            return foundVariant;
        });
        setSelectedVariant(
            variants && variants.length > 0 ? variants.filter(Boolean) : null
        );
    };

    // console.log("Selected variant -------", selectedVariant)

    const handleSizesSelect = (Sizes: string, variantIds: string) => {
        const selectedColorObject = availableSizes?.find(
            (c: any) => c?.value === Sizes
        );

        setSelectedSizes({
            size: selectedColorObject ? selectedColorObject?.attribute : null,
            sizeValue: Sizes,
            productId: selectedColorObject?.productId,
            productVariantId: selectedColorObject?._id,
        });

        const variants = variantId?.map((item: any, index: any) => {
            const foundVariant = item?.productInventory?.find(
                (variant: any) => variant?.productVariantId === variantIds
            );
            return foundVariant;
        });
        setSelectedVariant(
            variants && variants.length > 0 ? variants.filter(Boolean) : null
        );
    };

    const handleShadeClick = () => {
        setShowShadeOptions(!showShadeOptions);
    };

    const handleSizeClick = () => {
        setShowSizeOptions(!showSizeOptions);
    };

    let objectsWithSameShade = findObjectsWithSameShadeData(
        variantId,
        selectedColor?.colorName
    );

    let unitArray = objectsWithSameShade?.map((obj: any) => {
        return {
            _id: obj._id,
            attribute: obj?.variantAttributes?.find(
                (attr: any) =>
                    attr?.attribute === 'gm' ||
                    attr?.attribute === 'ml' ||
                    attr?.attribute === 'kg'
            )?.attribute,
            value: obj?.variantAttributes?.find(
                (attr: any) =>
                    attr?.attribute === 'gm' ||
                    attr?.attribute === 'ml' ||
                    attr?.attribute === 'kg'
            )?.value,
        };
    });

    const token = getCookie('accessToken');

    function addToCart() {
        if (!token) {
            setCookie('pathName', window.location.href);
            router.push('/login');
            return;
        }
        const storeId: any = window.localStorage.getItem('location');
        const payload: any = {
            storeId: storeId,
            productType: 'simple',
            productId: productId,
            quantity: 1,
        };
        if (type === 'variable') {
            payload['productType'] = 'variable';
            if (selectedColor && selectedSizes) {
                payload['productVariantId'] = selectedSizes.productVariantId;
                payload['productId'] = selectedSizes.productId;
            } else if (selectedColor) {
                payload['productVariantId'] = selectedColor.productVariantId;
                payload['productId'] = selectedColor.productId;
            } else if (selectedSizes) {
                payload['productVariantId'] = selectedSizes.productVariantId;
                payload['productId'] = selectedSizes.productId;
            }
        }

        if (type === 'variable') {
            payload['productType'] = 'variable';
            payload['productVariantId'] = productVariantId;
            payload['productId'] = productId;
        }

        dispatch(addCartItem(payload)).then((res: any) => {
            if (res?.payload?.status === 201 || res?.payload?.status === 200) {
                dispatch(
                    RemoveWistlistData({
                        productId: type === 'variable' ? id : productId,
                        productType: type,
                        errorMsg: true,
                    })
                ).then(() => {
                    dispatch(getWistlistData());
                });
            }
        });
    }

    const addtoWishlist = () => {
        if (toggleHeartIcon) {
            dispatch(
                RemoveWistlistData({
                    productId:
                        type === 'variable' ? productVariantId : productId,
                    productType: type,
                })
            ).then((res: any) => {
                dispatch(getWistlistData());
            });
        } else {
            dispatch(
                CreateWistlistData({
                    productId:
                        type === 'variable' ? productVariantId : productId,
                    productType: type,
                })
            ).then((res: any) => {
                dispatch(getWistlistData());
            });
            setCookie('pathName', window.location.href);
        }
        setToggleHeartIcon(!toggleHeartIcon);
    };

    return (
        <>
            <div
                // style={{ border: '1px solid red' }}
                className={className}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div>
                    {showShadeOptions && (
                        <div className=" w-full absolute top-0 right-0 h-[90%] @sm:h-[82%] rounded-t-lg @md:h-[80%] p-[3%] bg-white  overflow-auto z-20 ">
                            <div className="flex justify-between items-center w-full">
                                <div className="font-bold">Select Shade</div>
                                <div
                                    onClick={handleShadeClick}
                                    className="cursor-pointer"
                                >
                                    <Close />
                                </div>
                            </div>

                            <div className="mt-5 flex flex-wrap gap-3 ">
                                {availableColors &&
                                    availableColors?.map(
                                        (color: any, index: any) => (
                                            <div
                                                key={index}
                                                className={`w-10 h-10 rounded-full cursor-pointer flex justify-center items-center  ${
                                                    selectedColor?.color ===
                                                    color?.SelectedColor
                                                        ? 'bg-gray-300'
                                                        : ''
                                                }`}
                                                style={{
                                                    backgroundColor:
                                                        color?.hexCode,
                                                }}
                                                onClick={() =>
                                                    handleColorClick(
                                                        color?.hexCode,
                                                        color?._id
                                                    )
                                                }
                                            >
                                                {selectedColor.color ===
                                                    color?.hexCode && (
                                                    <div className="flex justify-center items-center border w-full h-full border-[#3b3b3b] rounded-full">
                                                        <CheckCircleIcon
                                                            sx={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )}
                                {selectedColor?.colorName && (
                                    <>
                                        <div className="absolute  right-0 px-3  bottom-0 mb-5 text-start w-[100%]">
                                            <p className="fs-16">
                                                {' '}
                                                {selectedColor?.colorName}
                                            </p>
                                            <div className="w-full flex justify-between">
                                                <div className="fs-16 mt-4">
                                                    ₹{' '}
                                                    {selectedVariant?.[0]
                                                        ?.discountPrice
                                                        ? selectedVariant?.[0]?.discountPrice?.toFixed(
                                                              0
                                                          )
                                                        : Price}
                                                </div>
                                                <div
                                                    onClick={onClick}
                                                    className="fs-16 mt-4 underline cursor-pointer"
                                                >
                                                    View details{' '}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {showSizeOptions && (
                        <div
                            id="z-index"
                            className="absolute top-0 w-full h-[90%] @sm:h-[82%] @md:h-[80%] rounded-t-lg  bg-white p-[3%]  overflow-auto z-20 "
                        >
                            <div className="flex justify-between items-center ">
                                <div className="font-bold">Select Sizes</div>
                                <div
                                    onClick={handleSizeClick}
                                    className="cursor-pointer"
                                >
                                    <Close />
                                </div>
                            </div>

                            <div className="mt-5 flex w-full flex-wrap gap-4 ">
                                {unitArray?.map((Sizes: any, index: any) => (
                                    <>
                                        <div
                                            key={index}
                                            className={`w-fit h-full rounded-xl cursor-pointer border my-2   ${
                                                selectedSizes?.sizeValue ===
                                                Sizes?.value
                                                    ? 'border border-neutral-300  text-[#fff]  bg-black px-3 py-2'
                                                    : 'border border-neutral-300 px-3 py-2'
                                            }`}
                                            onClick={() =>
                                                handleSizesSelect(
                                                    Sizes?.value,
                                                    Sizes?._id
                                                )
                                            }
                                        >
                                            <div className="fs-14">
                                                {Sizes?.value}{' '}
                                                {Sizes?.attribute}
                                            </div>
                                        </div>
                                        <div className="absolute bottom-0 mb-5 text-start w-[90%]">
                                            {selectedSizes.sizeValue ===
                                            Sizes?.value ? (
                                                <>
                                                    {' '}
                                                    <p className="fs-12">
                                                        {' '}
                                                        {
                                                            selectedSizes?.sizeValue
                                                        }{' '}
                                                        {Sizes?.attribute}
                                                    </p>
                                                    <div className="w-full flex justify-between">
                                                        <div className="fs-16 mt-4">
                                                            ₹
                                                            {selectedVariant?.[0]
                                                                ?.discountPrice
                                                                ? selectedVariant?.[0]
                                                                      ?.discountPrice
                                                                : Price}
                                                        </div>
                                                        <div className="fs-16 mt-4 underline cursor-pointer">
                                                            View details{' '}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-full " onClick={onClick}>
                    <div
                        className="w-full aspect-[4/4] @sm:aspect-square p-2 @sm:p-1 mb-3 rounded-t-lg "
                        style={{
                            backgroundImage: `url("${imagePath}")`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundColor: '#fff',
                        }}
                    >
                        <div
                            onClick={addtoWishlist}
                            className="flex justify-end "
                        >
                            <HeartIcon toggleHeartIcon={toggleHeartIcon} />
                        </div>
                    </div>
                    {/* <div className="aspect-[4/4] @sm:aspect-[2/1.5]">
                        <Image
                            onClick={onClick}
                            className={` object-contain  `}
                            alt={'Image'}
                            quality={100}
                            width={800}
                            height={800}
                            src={imagePath}
                        />
                    </div> */}
                    <div
                        onClick={onClick}
                        className="flex flex-col  w-full gap-2 my-5 @sm:mt-5 @sm:mb-2 px-3 cursor-pointer mb-10 @md:mb-5 "
                    >
                        <div className="text-[#BFBFBF] fs-20 text-start  h-6 overflow-hidden ">
                            {BrandName}
                        </div>
                        <div className="text-[#3B3B3B] fs-24 leading-tight text-start h-[3.65rem] overflow-hidden">
                            {productName
                                ? productName
                                      .toLowerCase() // Convert the whole string to lowercase
                                      .replace(/\b\w/g, (char) =>
                                          char.toUpperCase()
                                      ) // Capitalize the first character of each word
                                      .slice(0, 32) +
                                  (productName.length > 32 ? '...' : '') // Truncate if length exceeds 32 characters
                                : ''}
                        </div>

                        {Number(Price) === Number(cutPrice) ? (
                            <div className="flex gap-3">
                                <div className="text-[#0B1219] fs-20 text-start">
                                    ₹&nbsp;{Price}
                                </div>
                            </div>
                        ) : (
                            <div className="flex gap-3">
                                <div className="text-[#0B1219] fs-20 text-start">
                                    ₹&nbsp;{Price}
                                </div>
                                <div className="text-[#B9B9B9] fs-20 text-start">
                                    <del>₹&nbsp;{cutPrice}</del>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <div className="h-12 @sm:h-11 absolute flex gap-0 @sm:relative @md:relative ">
                        {Sizes && !isHovered && (
                            <div
                                className="mb-5 cursor-pointer "
                                onClick={handleSizeClick}
                            >
                                <div className="border rounded-3xl flex justify-center items-center w-fit p-2 fs-14 ml-2">
                                    <div className="flex"></div>
                                    {Sizes} {'Sizes'}
                                </div>
                            </div>
                        )}
                        {Shade > 0 && Shade !== null && !isHovered && (
                            <div
                                className="mb-5 cursor-pointer "
                                onClick={handleShadeClick}
                            >
                                <div className="border rounded-3xl flex justify-center items-center gap-2 w-fit p-2 fs-14 ml-1 ">
                                    <div className="flex mr-4 @sm:mr-3">
                                        <div className="w-3 h-3 bg-red-500 text-white text-center flex justify-center items-center rounded-full"></div>
                                        <div className="w-3 h-3 absolute translate-x-2 bg-red-900 text-white text-center flex justify-center items-center rounded-full"></div>
                                        <div className="w-3 h-3 absolute translate-x-4 bg-blue-500 text-white text-center flex justify-center items-center rounded-full">
                                            +
                                        </div>
                                    </div>
                                    {Shade}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className=" ">
                    <div
                        className={` right-0 left-0 w-ful ${
                            isHovered
                                ? 'visible'
                                : 'invisible @sm:visible @md:visible'
                        }`}
                    >
                        {showShadeOptions &&
                        selectedColor &&
                        showSizeOptions &&
                        selectedSizes ? (
                            <div
                                className=" w-full h-full"
                                onClick={() => addToCart()}
                            >
                                <div className="bg-[#FF8F2F] fs-20 font-bold p-4 @sm:p-2.5 text-white rounded-b-lg cursor-pointer">
                                    Add To Cart
                                </div>
                            </div>
                        ) : Shade && showShadeOptions === false ? (
                            <div className="" onClick={handleShadeClick}>
                                <div className="bg-[#FF8F2F] fs-20 font-bold p-4 @sm:p-2.5 text-white rounded-b-lg cursor-pointer">
                                    Select Shade
                                </div>
                            </div>
                        ) : Number(Sizes) > 1 && showSizeOptions === false ? (
                            <div onClick={handleSizeClick}>
                                <div className="bg-[#FF8F2F] fs-20 font-bold p-4 @sm:p-2.5 text-white rounded-b-lg cursor-pointer">
                                    Select Size
                                </div>
                            </div>
                        ) : (
                            <>
                                {isOutOfStock ? (
                                    <div
                                        className="bg-[#808080] fs-20 font-bold p-4 @sm:p-2.5 text-white rounded-b-lg cursor-pointer"
                                        // onClick={() => addToCart()}
                                    >
                                        Out Of Stock
                                    </div>
                                ) : (
                                    <div
                                        className="bg-[#FF8F2F] fs-20 font-bold p-4 @sm:p-2.5 text-white rounded-b-lg cursor-pointer"
                                        onClick={() => addToCart()}
                                    >
                                        Add To Cart
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductSlide;
