export const FEATURED_CATEGORY = '/home/featuredCategories';

export const BEST_SELLING_CATEGORY = '/home/categories/bestSelling';

export const BEST_SELLING_CATEGORY_PRODUCT =
    '/home/categories/bestSelling/products/v1';

export const INTRODUCING_NEW = '/product/introducing-new';

export const CRAZY_DEALS_LIST = '/crazyDeals';

export const GET_BANNER_LIST = '/banner';

export const BEST_SELLING_PRODUCT = '/home/bestSelling/products';

export const FEATURED_PRODUCT = '/home/featuredProducts';

export const CONSCIOUS_WALL_PRODUCT = '/home/consciousWall';

export const INTRODUCING_NEW_PRODUCT = '/home/introducingNew';

export const SUBSCRIBE_NOW = '/subscribe';

export const SEND_QUERY = '/user/contact-us';

export const USER_DETAIL = '/user';
